nav {
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue,
    helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    
}
nav a{
    text-decoration: none;
    font-weight: 700;
}

#site-name {
    clear: right;
    display: block;
}

#pages {
    float: left;
}

#pages > a + a{
    margin-left: .5rem;
}

#pages > a {
    font-weight: 400;
}

/* #pages > a + a:before {
    content: " / ";
} */

img {
    border-radius: 4px;
}

.highlight, .highlight .w {
    border-radius: 4px;
  }

.highlighter-rouge  code * {
    white-space: nowrap;
}

.highlighter-rouge pre {
    overflow-x: auto;
}

.highlighter-rouge pre code {
    white-space: pre;
}

/* prevent scrollbars from shifting content to the left */
body {
    overflow: overlay;
  }


.entries {
    margin-left: 0;
    padding-left: 0;
}

.entries > * {
    list-style-type: none;
    margin-bottom: 3rem;
}

.entry-title {
    font-size: var(--size-500);
}

@media (min-width: 45em) {
.entry-title {
    font-size: var(--size-700);
}
}

#backlinks {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.task-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
}