:root {
--accent-hue-light: 212;
--accent-hue-dark: 193;
--accent-2: hsl(0, 95%, 59%);
	/* Light theme */
	--nc-tx-1: hsl(0, 0%, 0%);
	--nc-tx-2: hsl(0, 0%, 10%);
	--nc-bg-1: hsl(0, 0%, 100%);
	--nc-bg-2: hsl(210, 29%, 97%);
	--nc-bg-3: hsl(220, 13%, 91%);
	--nc-lk-1: hsl(var(--accent-hue-light), 100%, 48%);
	--nc-lk-2: hsl(var(--accent-hue-light), 97%, 43%);
	--nc-lk-tx: hsl(0, 0%, 100%);
	--nc-ac-1: hsl(var(--accent-hue-light), 100%, 74%);
	--nc-ac-tx: hsl(var(--accent-hue-light), 71%, 16%);

	/* Dark theme */
	--nc-d-tx-1: hsl(0, 0%, 100%);
	--nc-d-tx-2: hsl(0, 0%, 93%);
	--nc-d-bg-1: hsl(0, 0%, 8%);
	--nc-d-bg-2: hsl(0, 0%, 16%);
	--nc-d-bg-3: hsl(0, 0%, 20%);
	--nc-d-lk-1: hsl(var(--accent-hue-dark), 100%, 60%);
	--nc-d-lk-2: hsl(var(--accent-hue-dark), 100%, 48%);
	--nc-d-lk-tx: hsl(0, 0%, 100%);
	--nc-d-ac-1: hsl(var(--accent-hue-dark), 67%, 47%);
	--nc-d-ac-tx: hsl(0, 0%, 100%);
}