:root {
    --size-300: 0.75rem;
    --size-400: 1rem;
    --size-500: 1.33rem;
    --size-600: 1.77rem;
    --size-700: 2.36rem;
    --size-800: 3.15rem;
    --size-900: 4.2rem;
  }
  
  html {
    font-size: 18px;
  }
  body {
    line-height: 1.5;
    font-family: Georgia, serif;
    padding: 2rem;
  }
  
  a:not([class]) {
    color: currentColor;
  }
  
  :focus {
    outline: 2px dashed;
    outline-offset: 0.25rem;
  }
  
  main:focus {
    outline: none;
  }

  a:hover {
    outline: none;
  }
  
  [href]:hover {
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4 {
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue,
      helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    line-height: 1.3;
  }
  
  h1,
  h2 {
    font-weight: 900;
  }
  
  h1 {
    font-size: var(--size-700);
  }
  
  h2 {
    font-size: var(--size-600);
  }
  
  h3 {
    font-size: var(--size-600);
  }
  
  figcaption {
    font-size: var(--size-300);
  
    font-style: italic;
    margin-top: 1rem;
  }
  
  blockquote {
    padding-left: var(--size-600);
    border-left: 5px solid;
    font-style: italic;
  }
  
  .flow > * + * {
    margin-top: var(--flow-space, 1em);
  }
  
  p,
  li {
    max-width: 60ch;
  }
  
  h1 {
    max-width: 15ch;
  }
  
  h2,
  h3 {
    max-width: 25ch;
  }
  
  .wrapper {
    --flow-space: var(--size-700);
  }
  
  .wrapper time {
    --flow-space: var(--size-400);
    display: block;
  }
  
  .wrapper :is(h2, h3) {
    --flow-space: var(--size-900);
  }
  
  .wrapper :is(h2, h3) + * {
    --flow-space: var(--size-400);
  }
  
  .wrapper figure,
  .wrapper figure + * {
    --flow-space: var(--size-900);
  }
  
  @media (min-width: 45em) {
    h1 {
      font-size: var(--size-900);
    }
  
    h2 {
      font-size: var(--size-700);
    }
  
    p {
      font-size: var(--size-500);
    }
  
    figcaption {
      font-size: var(--size-400);
    }
  
    blockquote p {
      font-size: var(--size-600);
      max-width: 40ch;
    }
  
    .wrapper time {
      font-size: var(--size-400);
    }
  }
  